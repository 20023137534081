<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showForm"
        persistent
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            {{ formTitle }}
            <v-spacer> </v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="save"
              :disabled="change == 1"
              v-if="modify"
            >
              Enregistrer
            </v-btn>

            <v-btn color="blue darken-1" text @click="close">
              {{ change == 1 ? "Fermer" : "Annuler" }}
            </v-btn>
          </v-card-title>
          <v-card-subtitle>
            Date de Creation : {{ datefr(editedItem.created_at) }}
            <v-switch
              v-model="editedItem.active"
              :label="editedItem.active ? 'Actif' : 'Obsolete'"
              :readonly="!modify"
              :color="editedItem.active ? 'green' : 'red'"
            ></v-switch>
          </v-card-subtitle>

          <v-card-text class="pa-3">
            <v-row dense>
              <v-col cols="12" lg="8" md="8" sm="8">
                <v-card flat class="pa-3">
                  <v-row dense>
                    <v-col cols="12" lg="2" sm="2" md="2">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.ref"
                        label="Réf"
                        :readonly="!modify || editedItem.variantscount != 0"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="9" sm="9" md="9">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.label"
                        label="Libelle"
                        :rules="[
                          (v) =>
                            !!v ||
                            editedItem.tomanufacture == 1 ||
                            editedItem.tosell == 1 ||
                            'Libelle obligatoire',
                        ]"
                        :readonly="
                          !modify ||
                          editedItem.variantscount != 0 ||
                          editedItem.tosell == 1 ||
                          editedItem.tomanufacture == 1
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="1" sm="1" md="1">
                      <v-checkbox
                        v-model="editedItem.included"
                        label="Affiché"
                        :readonly="!modify"
                        class="mt-1"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        :items="[
                          { id: '0', label: 'Produit ' },
                          { id: '1', label: 'Service' },
                        ]"
                        v-model="editedItem.service"
                        item-text="label"
                        item-value="id"
                        label="Nature de produit"
                        :rules="[(v) => !!v || 'Nature de produit obligatoire']"
                        outlined
                        dense
                        :readonly="!modify || editedItem.variantscount != 0"
                        @change="service_change"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-select
                        :items="products_types"
                        v-model="editedItem.fk_product_type"
                        item-text="label"
                        item-value="id"
                        label="Type de Produit"
                        outlined
                        :rules="[(v) => !!v || 'Type de Produit obligatoire']"
                        dense
                        :readonly="!modify || editedItem.variantscount != 0"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="1" md="1"> </v-col>
                    <v-col cols="12" sm="1" md="1" v-if="bag">
                      <v-checkbox
                        v-model="editedItem.bag_need"
                        label="BAG"
                        :readonly="!modify"
                        class="mt-1"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        v-model="editedItem.is_moule"
                        label="Moule"
                        :readonly="!modify"
                        class="mt-1"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="1" md="1">
                      <v-checkbox
                        v-model="editedItem.support"
                        label="Support"
                        :readonly="!modify"
                        class="mt-1"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="8" md="8">
                      <selecttree
                        :items="cat_list"
                        v-model="categories"
                        :value="categories"
                        :label="'Categories'"
                        :nbr_chips="4"
                        class="pb-4"
                      >
                      </selecttree>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        outlined
                        dense
                        v-model="editedItem.description"
                        label="Description"
                        required
                        :readonly="!modify"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" sm="3" md="3">
                      <v-select
                        :items="barcodetypes"
                        v-model="editedItem.fk_barcode_type"
                        item-text="libelle"
                        item-value="id"
                        label="Type de code-barres"
                        outlined
                        dense
                        :readonly="!modify"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.customcode"
                        label="Nomenclature douanière"
                        required
                        :readonly="!modify"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-autocomplete
                        :items="plancomptable"
                        :item-text="
                          (item) => item.account_number + ' - ' + item.label
                        "
                        item-value="id"
                        outlined
                        dense
                        v-model="editedItem.code_cmpt"
                        label="Code Comptable"
                        :readonly="!modify"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model.number="editedItem.tva_tx"
                        type="number"
                        label="Taux TVA"
                        :readonly="!$store.state.isadmin"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-divider vertical inset></v-divider>
              <v-col cols="12" lg="4" md="4" sm="4">
                <v-card flat class="pa-3">
                  <v-row dense>
                    <v-col cols="12" sm="4" md="4">
                      <v-checkbox
                        v-model="editedItem.tosell"
                        label="En vente"
                        class="mt-1"
                        :readonly="!modify"
                        :disabled="
                          item.tosell == 1 && editedItem.variantscount != 0
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-checkbox
                        v-model="editedItem.tobuy"
                        label="En achat"
                        class="mt-1"
                        :readonly="!modify"
                        :disabled="
                          item.tobuy == 1 && editedItem.variantscount != 0
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-checkbox
                        v-model="editedItem.tomanufacture"
                        label="En Fabrication"
                        class="mt-1"
                        :readonly="!modify"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-autocomplete
                        :items="template_attributs"
                        v-model="editedItem.tabtempattributs"
                        item-text="name"
                        item-value="id"
                        label="Attributs de variantes"
                        outlined
                        :readonly="!modify"
                        multiple
                        small-chips
                        :rules="[
                          (v) =>
                            v.length > 0 ||
                            'Attributs de variantes obligatoire',
                        ]"
                        :disabled="editedItem.variantscount > 0"
                        @input="att_change"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="colis_attributs"
                        v-model="editedItem.tabcolisattributs"
                        item-text="name"
                        item-value="id"
                        label="Attributs de Colisage"
                        outlined
                        dense
                        :readonly="!modify"
                        multiple
                        small-chips
                        v-on:input="limiter"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="template_attributs"
                        v-model="ticket_label"
                        item-text="name"
                        item-value="id"
                        label="Libelle du ticket"
                        outlined
                        dense
                        :readonly="!modify"
                        multiple
                        small-chips
                        v-on:input="limiter"
                      >
                      </v-select>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="3" md="3">
                      <v-select
                        :items="units.filter((elm) => elm.uom_type == 'm')"
                        v-model="editedItem.fk_unit"
                        item-text="name"
                        item-value="id"
                        label="Unité de Mesure"
                        :rules="[(v) => !!v || 'Unité obligatoire']"
                        outlined
                        dense
                        @change="unit_change"
                        :readonly="!modify || editedItem.variantscount != 0"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="editedItem.tobuy">
                      <v-autocomplete
                        :items="units.filter((elm) => elm.uom_type == 'm')"
                        v-model="editedItem.fk_unit_buy"
                        item-text="name"
                        item-value="id"
                        label="Unité d'Achat"
                        outlined
                        dense
                        :readonly="!modify"
                        @change="unit_change"
                      >
                        <template v-slot:item="{ item }">
                          <!-- HTML that describe how select should render selected items -->
                          {{ item.name + " (" + item.category_name + ")" }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" v-if="editedItem.tobuy">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        type="number"
                        v-model.number="editedItem.factor"
                        label="Facteur"
                        :readonly="!modify"
                        :disabled="!editedItem.fk_unit_buy"
                        hide-spin-buttons
                        hint="Unité Achat * facteur = Unité Mesure"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        type="number"
                        v-model.number="editedItem.decimal"
                        label="Precision"
                        :readonly="!modify"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      v-if="editedItem.fk_product_type == 1"
                    >
                      <v-select
                        :items="
                          units.filter(
                            (elm) =>
                              elm.uom_type == 'm' && elm.category_id == cat_unit
                          )
                        "
                        v-model="editedItem.fk_unit_ratio"
                        item-text="name"
                        item-value="id"
                        label="Unité de Ratio"
                        :rules="[(v) => !!v || 'Unité obligatoire']"
                        outlined
                        dense
                      ></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="
                        editedItem.service == '0' &&
                        $store.state.structures.filter(
                          (elm) => elm.type_id == 1
                        ).length == 1
                      "
                    >
                      <v-autocomplete
                        outlined
                        :items="depots"
                        v-model="editedItem.depot_id"
                        item-text="label"
                        item-value="id"
                        label="Entrepôt par défaut"
                        :rules="[
                          (v) => !!v || 'Entrepôt par défaut obligatoire',
                        ]"
                        :readonly="!modify"
                        dense
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-checkbox
                        v-model="editedItem.bylist"
                        label="Gestion Par Liste"
                        class="mt-1"
                        :readonly="!modify"
                        :disabled="editedItem.variantscount != 0"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.minvalue"
                        label="Quantite Min/item"
                        type="number"
                        :readonly="!modify"
                        :disabled="editedItem.bylist == 0"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <v-text-field
                        autocomplete="off"
                        outlined
                        dense
                        v-model="editedItem.maxvalue"
                        label="Quantite Max/item"
                        type="number"
                        :readonly="!modify"
                        :disabled="editedItem.bylist == 0"
                        hide-spin-buttons
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-select
                        :items="units.filter((elm) => elm.uom_type == 'c')"
                        v-model="editedItem.fk_unit_colis"
                        item-text="name"
                        item-value="id"
                        label="Unité de Colisage"
                        :rules="[(v) => !!v || 'Unité obligatoire']"
                        outlined
                        dense
                        v-if="editedItem.bylist == 1"
                        :readonly="!modify"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-checkbox
                        v-model="editedItem.partial"
                        label="Sortie Partielle"
                        :readonly="!modify"
                        :disabled="editedItem.bylist == 0"
                        class="mt-1"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <!-- <variantform
      :Template="editedItem"
      :showForm="!isVariantClosed"
      :variant_list="variant_list"
      :key="vf"
      @close="closeVariantForm"
      :attributs="template_attributs"
      :values_list="values"
    >
    </variantform> -->
  </div>
</template>

<script>
import CREATE_TEMPLATE from "../graphql/Template/CREATE_TEMPLATE.gql";
import UPDATE_TEMPLATE from "../graphql/Template/UPDATE_TEMPLATE.gql";
import INSERT_TAP from "../graphql/Template/INSERT_TAP.gql";
import INSERT_TAV from "../graphql/Template/INSERT_TAV.gql";
import DELETE_PRODUCT from "../graphql/Product/DELETE_PRODUCT.gql";
import { bag } from "print/data.js";
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    selecttree: () => import("../components/SelectTree.vue"),
    //  listitems: () => import("../components/ListItems.vue"),
    //  variantform: () => import("../components/VariantForm.vue"),
    //  datepicker: () => import("../components/DatePicker.vue"),
  },
  props: {
    item: Object,
    showForm: Boolean,
    attributs: Array,
    values_list: Array,
    cat_list: Array,
    barcodetypes: Array,
    depots: Array,
    units: Array,
    plancomptable: Array,
    boms: Array,
    templates: Array,
  },
  data: () => ({
    boms_values: [],
    process: {},
    vl: 0,
    vf: 100,
    modify: false,
    change: 0,
    chta: 0,
    chpa: 0,
    tabs: null,
    x: "",
    valid: true,
    catpicker1: false,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    cat_buy: null,
    cat_unit: null,
    defaultItem: {
      id: -1,
      created_at: null,
      variantscount: 0,
      decimal: 2,
      tabtempattributs: [],
      tabcolisattributs: [],
      // variant_list: [],
    },
    categories: [],
    //variant_list: [],
    // variant_headers: [
    //   {
    //     text: "Code",
    //     value: "code",
    //     enum: "CODE",
    //     selected: true,
    //   },
    //   {
    //     text: "Libelle",
    //     align: "start",
    //     value: "label",
    //     enum: "LABEL",
    //     selected: true,
    //   },

    //   {
    //     text: "Attributs",
    //     value: "tabvarattributs",
    //     sortable: false,
    //     selected: true,
    //     slot: "select",
    //     select_list: [],
    //     item_value: "id",
    //     item_text: "name",
    //     item_color: "html_color",
    //     multiple: true,
    //   },

    //   {
    //     text: "Date de Création",
    //     value: "created_at",
    //     enum: "CREATED_AT",
    //     selected: true,
    //   },
    // ],
    isVariantClosed: true,
    product: {},
    add_variant: false,
    listx: [],
    ticket_label: [],
  }),

  computed: {
    bag() {
      return bag;
    },
    products_types() {
      return this.$store.state.products_types.filter(
        (elm) => elm.nature == this.editedItem.service
      );
    },
    colis_attributs() {
      return this.attributs.filter((elm) => elm.type_attr == "colis");
    },
    template_attributs() {
      return this.attributs.filter((elm) => elm.type_attr == "variante");
    },

    Qdetaildelete() {
      return DELETE_PRODUCT;
    },

    bartype() {
      let c;
      if (this.editedItem.fk_barcode_type) {
        let i = this.barcodetypes.findIndex(
          (elm) => elm.id == this.editedItem.fk_barcode_type
        );
        if (i >= 0) c = this.barcodetypes[i].code;
      }

      return c;
    },

    itemchange() {
      this.editedItem.ref;
      this.editedItem.label;
      this.editedItem.description;
      this.editedItem.note;
      this.editedItem.customcode;
      this.editedItem.tosell;
      this.editedItem.tobuy;
      this.editedItem.tomanufacture;
      this.editedItem.is_moule;
      this.editedItem.bag_need;
      this.editedItem.included;
      this.editedItem.active;
      this.editedItem.support;
      this.editedItem.bylist;
      this.editedItem.fk_product_type;
      this.editedItem.fk_barcode_type;
      this.editedItem.depot_id;
      this.editedItem.fk_unit;
      this.editedItem.fk_unit_colis;
      this.editedItem.fk_unit_buy;
      this.editedItem.factor;
      this.editedItem.created_at;
      this.editedItem.tva_tx;
      this.editedItem.maxvalue;
      this.editedItem.partial;
      this.editedItem.minvalue;
      this.editedItem.prixvente;
      this.categories;
      this.editedItem.tabtempattributs;
      this.editedItem.tabcolisattributs;
      this.ticket_label;
      this.editedItem.decimal;
      this.editedItem.fk_unit_ratio;
      this.editedItem.code_cmpt;
      return Date.now();
    },
    tempattributs_change() {
      this.editedItem.tabtempattributs;
      return Date.now();
    },
    colisattributs_change() {
      this.editedItem.tabcolisattributs;
      return Date.now();
    },
    formTitle() {
      return this.editedItem.id < 0
        ? "Nouveau Modele de Produit  "
        : "Modele de Produit : " + this.editedItem.label;
    },
  },
  watch: {
    itemchange: {
      handler() {
        this.change++;
      },
      deep: true,
    },
    tempattributs_change: {
      handler() {
        this.chta++;
      },
      deep: true,
    },
    colisattributs_change: {
      handler() {
        this.chpa++;
      },
      deep: true,
    },
  },

  created() {},
  async mounted() {
    if (this.boms) {
      this.boms_values = [];
      this.boms.forEach((element) => {
        let elm = Object.assign({}, element);
        this.boms_values.push(elm);
      });
    }
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        if (this.editedItem.categories) {
          this.categories = this.editedItem.categories.split(",");
        }
        if (this.editedItem.ticket_label) {
          this.ticket_label = this.editedItem.ticket_label.split(",");
        }

        this.editedItem.bylist = this.editedItem.bylist == "1";
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit
        );
        if (i >= 0) this.cat_unit = this.units[i].category_id;
        // if (this.editedItem.variants)
        //   this.variant_list = this.editedItem.variants;
      } else {
        this.defaultItem.created_at = this.$store.state.today;
        this.editedItem = Object.assign({}, this.defaultItem);

        this.editedItem.tva_tx =
          this.$store.state.options[0].default_product_tva;
      }
    }

    this.modify =
      this.$store.state.auth.includes("02018") || this.$store.state.isadmin;
    //this.variant_headers[2].select_list = this.values;
    this.att_change();
  },

  methods: {
    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    unit_change() {
      if (!this.editedItem.fk_unit_buy) {
        this.editedItem.fk_unit_buy = this.editedItem.fk_unit;
        this.editedItem.factor = 1;
      }
      if (this.units) {
        let i = this.units.findIndex(
          (elm) => elm.id == this.editedItem.fk_unit
        );
        if (i >= 0) {
          this.cat_unit = this.units[i].category_id;
          this.editedItem.rounding = this.units[i].rounding;

          let j = this.units.findIndex(
            (elm) => elm.id == this.editedItem.fk_unit_buy
          );
          if (j >= 0) this.cat_buy = this.units[j].category_id;
          if (this.cat_unit == this.cat_buy) {
            let factor_unit_product = this.units[i].factor
              ? this.units[i].factor
              : 0;
            let factor_unit_buy = this.units[j].factor
              ? this.units[j].factor
              : 1;
            this.editedItem.factor = factor_unit_buy / factor_unit_product;
          }
        }
      }
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    service_change() {
      if (this.editedItem.service == 1) {
        this.editedItem.fk_product_type = null;
        this.editedItem.tva_tx =
          this.$store.state.options[0].default_service_tva;
      } else {
        this.editedItem.tva_tx =
          this.$store.state.options[0].default_product_tva;
        if (this.products_types.length == 1)
          this.editedItem.fk_product_type = this.products_types[0].id;
      }
    },
    limiter(e) {
      if (e.length > 6) {
        this.snackbar_text = "Nombre maximum d'attributs atteint";
        this.snackbar_color = "error";
        this.snackbar = true;
        e.pop();
      }
    },
    VariantSelect(item) {
      this.product = item;
    },
    OpenVariantForm() {
      this.isVariantClosed = false;
      this.vf++;
    },
    closeVariantForm() {
      this.vf++;
      this.isVariantClosed = true;
    },
    async close() {
      if (this.change > 2) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir quitter?"
          )
        ) {
          this.$emit("close");
        }
      } else this.$emit("close");
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async maj(query, v, refresh) {
      let r;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          if (refresh) this.$store.dispatch("Changed", true);
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    updateAttribut(query, att_list) {
      let list = [];
      if (att_list) {
        att_list.forEach((attribut) => {
          list.push({
            template_id: this.editedItem.id,
            attribute_id: attribut,
            create_uid: this.$store.state.me.id,
            write_uid: this.$store.state.me.id,
          });
        });
        if (list.length > 0) {
          let v = {
            template_id: this.editedItem.id,
            list: list,
          };
          this.maj(query, v);
        }
      }
    },
    att_change() {
      this.add_variant =
        this.modify && this.editedItem.tabtempattributs.length > 0
          ? true
          : false;
      this.vl++;
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.editedItem.categories = this.categories.toString();
        let ok = true;
        //verif if support there is laize and conversion unit to M2
        if (this.editedItem.support == 1) {
          let j = this.units.findIndex(
            (elm) => elm.id == this.editedItem.fk_unit
          );
          let cat_modele = this.units[j].category_id;
          let uc = true;
          if (this.editedItem.fk_product_type == 1 && cat_modele == 4)
            uc = false;
          if (this.editedItem.fk_product_type == 2 && cat_modele == 6)
            uc = false;
          let list = [];
          if (uc)
            list = this.attributs.filter(
              (elm) =>
                this.editedItem.tabtempattributs.includes(elm.id) &&
                (elm.laize == 1 || elm.fk_unit_m2 != null)
            );
          else
            list = this.attributs.filter(
              (elm) =>
                this.editedItem.tabtempattributs.includes(elm.id) &&
                elm.laize == 1
            );
          if (list.length != (uc ? 2 : 1)) {
            let s = uc ? "et un attribut de conversion en M2" : "";
            this.snackbar_text =
              "Le Modele doit contenir un attribut laize " + s;
            this.snackbar_color = "error";
            this.snackbar = true;
            ok = false;
          }
          if (ok && uc) {
            // verif cat of conversion unit to M2
            let l = this.attributs.filter(
              (elm) =>
                this.editedItem.tabtempattributs.includes(elm.id) &&
                elm.fk_unit_m2 != null
            );
            let i = this.units.findIndex((elm) => elm.id == l[0].fk_unit_m2);

            if (i >= 0 && j >= 0)
              if (this.units[i].category_id != cat_modele) {
                this.snackbar_text =
                  "L'attribut de conversion en M2 est incompatible avec l'unite de mesure";
                this.snackbar_color = "error";
                this.snackbar = true;
                ok = false;
              }
          }
        }
        if (ok) {
          if (this.editedItem.id > -1) {
            if (!this.editedItem.decimal) this.editedItem.decimal = 0;
            let v = {
              product: {
                id: this.editedItem.id,
                ref: this.editedItem.ref,
                label: this.editedItem.label,
                description: this.editedItem.description,
                note: this.editedItem.note,
                customcode: this.editedItem.customcode,
                tosell: this.editedItem.tosell ? 1 : 0,
                tobuy: this.editedItem.tobuy ? 1 : 0,
                tomanufacture: this.editedItem.tomanufacture ? 1 : 0,
                is_moule: this.editedItem.is_moule ? 1 : 0,
                bag_need: this.editedItem.bag_need ? 1 : 0,
                bylist: this.editedItem.bylist ? 1 : 0,
                partial: this.editedItem.partial ? 1 : 0,
                service: this.editedItem.service,
                fk_product_type: this.editedItem.fk_product_type,
                included: this.editedItem.included ? 1 : 0,
                support: this.editedItem.support ? 1 : 0,
                active: this.editedItem.active ? 1 : 0,
                tva_tx: this.editedItem.tva_tx,
                maxvalue:
                  this.editedItem.maxvalue > 0
                    ? parseFloat(this.editedItem.maxvalue)
                    : null,
                minvalue:
                  this.editedItem.minvalue > 0
                    ? parseFloat(this.editedItem.minvalue)
                    : null,
                ticket_label: this.ticket_label.toString(),
                categories: this.editedItem.categories,
                fk_barcode_type: this.editedItem.fk_barcode_type,
                depot_id: this.editedItem.depot_id,
                fk_unit: this.editedItem.fk_unit,
                fk_unit_buy: this.editedItem.fk_unit_buy,
                fk_unit_colis: this.editedItem.fk_unit_colis,
                fk_unit_ratio: this.editedItem.fk_unit_ratio,
                factor: this.editedItem.factor,
                prixvente: this.editedItem.prixvente,
                decimal: parseInt(this.editedItem.decimal),

                code_cmpt: this.editedItem.code_cmpt,
                //      process_id: this.editedItem.process_id,

                write_uid: this.$store.state.me.id,
              },
            };
            await this.maj(UPDATE_TEMPLATE, v, true);
            if (this.chta > 0) {
              this.updateAttribut(INSERT_TAV, this.editedItem.tabtempattributs);
              this.chta = 0;
            }
            if (this.chpa > 0) {
              this.updateAttribut(
                INSERT_TAP,
                this.editedItem.tabcolisattributs
              );
              this.chpa = 0;
            }

            this.change = 1;
          } else {
            let v = {
              product: {
                ref: this.editedItem.ref,
                label: this.editedItem.label,
                description: this.editedItem.description,
                note: this.editedItem.note,
                customcode: this.editedItem.customcode,
                tosell: this.editedItem.tosell ? 1 : 0,
                tobuy: this.editedItem.tobuy ? 1 : 0,
                tomanufacture: this.editedItem.tomanufacture ? 1 : 0,
                is_moule: this.editedItem.is_moule ? 1 : 0,
                bylist: this.editedItem.bylist ? 1 : 0,
                partial: this.editedItem.partial ? 1 : 0,
                service: this.editedItem.service,
                fk_product_type: this.editedItem.fk_product_type,
                ticket_label: this.ticket_label.toString(),
                tva_tx: this.editedItem.tva_tx,
                maxvalue:
                  this.editedItem.maxvalue > 0
                    ? parseFloat(this.editedItem.maxvalue)
                    : null,
                minvalue:
                  this.editedItem.minvalue > 0
                    ? parseFloat(this.editedItem.minvalue)
                    : null,
                categories: this.editedItem.categories,
                included: this.editedItem.included ? 1 : 0,
                support: this.editedItem.support ? 1 : 0,
                active: this.editedItem.active ? 1 : 0,
                fk_barcode_type: this.editedItem.fk_barcode_type,
                depot_id: this.editedItem.depot_id,
                fk_unit: this.editedItem.fk_unit,
                fk_unit_buy: this.editedItem.fk_unit_buy,
                fk_unit_colis: this.editedItem.fk_unit_colis,
                fk_unit_ratio: this.editedItem.fk_unit_ratio,
                factor: this.editedItem.factor,
                code_cmpt: this.editedItem.code_cmpt,

                create_uid: this.$store.state.me.id,
                write_uid: this.$store.state.me.id,
              },
            };
            let r = await this.maj(CREATE_TEMPLATE, v, true);
            if (r) this.editedItem.id = r.createProductTemplate.id;
            if (this.chta > 0) {
              this.updateAttribut(INSERT_TAV, this.editedItem.tabtempattributs);
              this.chta = 0;
            }
            if (this.chpa > 0) {
              this.updateAttribut(
                INSERT_TAP,
                this.editedItem.tabcolisattributs
              );
              this.chpa = 0;
            }
            this.change = 1;
          }
        }
      }
    },
  },
};
</script>
